<template>
<div class="px-2 pb-3">
  
    <h2 class="mt-1 mb-2 border-bottom-secondary p-25">
      <b-link class="text-success" to="home">FiqOn</b-link> 
      Politica de Privacidade
    </h2> 
   
      
    <main class="policy-container">

        <section class="mb-2">
          <h3>O que esta política cobre</h3>

          <p>
            A sua privacidade é importante para nós, assim como ser transparente sobre o que coletamos, usamos e compartilhamos informações sobre você. 
            Este documento destina-se a ajuda-lo a entender: 
            <ol>
              <li>Quais informações coletamos sobre você </li>
              <li>Como usamos as informações que coletamos </li>
              <li>Outras informações importante sobre privacidade </li>
              <li>Como compartilhamos informações pessoais </li>
              <li>Segurança de dados </li>
            </ol>
          </p>
        </section>

        <h3>Descrição: </h3>
          
        <h4>1. Quais informações coletamos sobre você.</h4>
        <section>
          <p>
            <b> Informações de acesso a plataformas utilizadas nas integrações: </b>
            Coletamos informações de acesso as plataformas para utilizar recursos como chaves de acesso e confirmação de conexões com plataformas externas, além de 
            observar dados relevantes sobre os testes que são realizados durante o processo de integração dos sistemas.
          </p>

          <p>
            <b> Cookies e outras tecnologias de rastreamento </b>
            A FiqOn usam cookies e outras tecnologias de rastreamento para fornecer funcionalidade e reconhecê-lo em diferentes serviços e dispositivos. 
          </p>
          
          <p>
            <b> Informações que recebemos de outras fontes </b>
            Recebemos informações sobre você de outros serviços de terceiros que fazem parte do fluxo de nossas integrações, onde combinamos essas informações 
            que coletamos para realizar outras ações com também outras plataformas externas. 
          </p>
        </section>

        <h4>2. Como usamos as informações que coletamos</h4>
        <section>
          <p>
              <b> Para fornecer os serviços e personalizar suas integrações </b>
              Usamos informações sobre você para fornecer serviços personalizados em suas integrações, com objetivo de auxiliar processos e facilidade nas soluções. 
          </p>

          <p>
            <b> Para suporte ao cliente </b>
            Usamos suas informações para resolver problemas técnicos que você encontrar, para responder ás suas solicitações de assistência, para analisar informações 
            sobre falhas e para reparar e melhorar os Serviços. 
          </p>
          
          <p>
            <b> Com o seu consentimento </b>
            Usamos informações sobre você quando você nos deu consentimento para faze-lo para uma finalizada específica não listada acima. Por exemplo, podemos publicar depoimentos
            ou histórias de clientes em destaque para promover os Serviços, com sua permissão. 
          </p>
        </section>
        <h4>3. Outras informações importante sobre privacidade</h4> 
        <section>
          <p>
            <b> Alterações à nossa política de privacidade </b>
            Podemos alterar esta política de privacidade de tempos em tempos. Publicaremos quaisquer alterações da política de privacidade nesta página e, se as alterações 
            forem significativas, forneceremos um aviso mais proeminente adicionando um aviso nas páginas iniciais dos Serviços. 
          </p>

          <p>
            <b> Registro das informações adquiridas </b>
            Todas informações que são adquiridas são usadas somente para controle e criação das integrações. Nenhum tipo de informação relevante são armazenadas. 
          </p>
        </section>

        <h4>4. Como compartilhamos informações pessoais</h4>
        <section>
          <p>
            <b> Com o seu consentimento </b>
            Podemos compartilhar suas informações com empresas, organizações e outros terceiros com o seu consentimento ou para cumprir a finalidade para qual você forneceu 
            as informações 
          </p>

          <p>
            <b> Com nossos parceiros </b>
            Podemos compartilhar informações com nossos parceiros (incluindo revendedores, referências, parceiros de integração, especialistas em nossa comunidade) para fins do contrato de parceria ou para habilitar nossos programas de parceria. 
          </p>

          <p>
            <b> Para fornecer os serviços, programas de parceiros ou comunidades </b>
            podemos compartilhar suas informações com seus colegas de equipe, administradores de contas, cobrança ou usuários técnicos de contas. Também compartilharemos suas informações com aplicativos de terceiros (sob sua orientação ou orientação do proprietário da conta) quando o titular da conta usar os Serviços. Para fornecer o Site, Serviços, programas de parceiros ou comunidades, podemos compartilhar suas informações com nossos provedores de serviços. Se você participa de nossos programas ou comunidades de parceiros, podemos compartilhar suas informações com outros parceiros, membros da comunidade, clientes ou outros usuários (como quando você comenta ou compartilha informações, classifica um especialista ou expressa interesse em uma integração de parceiro).
          </p>

          <p>
            <b> Para fins comerciais </b>
            fornecemos informações a fornecedores e provedores de serviços para nos ajudar a fornecer o Site, Serviços, programas de parceiros e comunidades e para nossos fins comerciais. Exemplos de nossos provedores de serviços incluem provedores de hospedagem ou infraestrutura, widgets de bate-papo, provedores de tíquetes de helpdesk, hosts de sites, provedores de gerenciamento de relacionamento com clientes, provedores de e-mail, ferramentas de colaboração e outros provedores de serviços.
          </p>    
        </section>
        <h4>5. Segurança de dados</h4>
        
        <section>
          <p>
            <b> Medidas </b>
            A plataforma FiqOn toma medidas para proteger suas informações pessoais contra perda, uso indevido e acesso não autorizado, divulgação, alteração ou destruição, levando em consideração os riscos envolvidos no processamento e a natureza dos dados pessoais. No entanto, nenhum aplicativo, Internet ou transmissão de e-mail é totalmente seguro ou livre de erros, e não garantimos a segurança de nenhuma informação pessoal. Você também é responsável por garantir que sua conta e as senhas que fornecemos a você estejam protegidas.
          </p>
        </section>

        <h4>6. API do Google</h4>
        <section>
          <p>
            O uso e a transferência de informações recebidas de APIs do Google para qualquer outro aplicativo obedecerão à <a href="https://developers.google.com/terms/api-services-user-data-policy">Política de dados do usuário dos serviços de API do Google</a>, incluindo os requesitos de uso limitado.
          </p>
        </section>

      </main>
    
</div>

</template>

<script>
import {
  BCard,
  BModal,
  BCollapse,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {    
    BCard,
    BModal,
    BCollapse,
    BLink,
  },  
  methods: {
    show() {
      this.$refs['privacy-policy-modal'].show();
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

.policy-container{
  h3{
    font-size: 18px;
    color: $primary;
  }
  h4{
    font-size: 15px;
    font-weight: bolder;
    color: $success;
  }

  ol {
    margin-top: 5px;
    padding-left: 20px;
    list-style: none;
    counter-reset: counter;
  }
  ol li {
    counter-increment: counter;
  }
  ol li::before {
    content: counter(counter) ". ";
    color: $success;
    font-weight: bold;
  }
  p{
    text-align: justify;
    margin-left: 10px;
    margin-block: 8px;
  }
}

</style>